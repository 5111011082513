<template>
  <el-dialog
    class="ServiceExpiredDialog"
    width="715px"
    :visible="true"
    title="服务过期提醒"
    :before-close="close"
  >
    <p class="tip">
      您购买的扩容额度，将于2022-12-15日过期，以下已发布的服务将无法正常使用，若180天内服务依然无法正常使用将被释放，为避免不可挽回的数据损失，请您及时<router-link
        class="link-text"
        to=""
        >扩容</router-link
      >或<router-link class="link-text" to="">升级会员</router-link>
    </p>
    <div class="wrap">
      <router-link class="link" to="">
        <img class="link-icon" src="~a/workbench/expansion.png" alt="" />
        <p class="link-name">扩容</p>
      </router-link>
      <router-link class="link" to="">
        <img class="link-icon" src="~a/workbench/member.png" alt="" />
        <p class="link-name">升级会员</p>
      </router-link>
    </div>
    <div class="list-wrap">
      <p class="list-title">服务名称</p>
      <ul class="list">
        <li class="list-item">服务名称123321213</li>
        <li class="list-item">服务名称123321213</li>
        <li class="list-item">服务名称123321213</li>
        <li class="list-item">服务名称123321213</li>
      </ul>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'ServiceExpiredDialog',
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>
.tip {
  margin-bottom: 20px;
  color: #333;
  font-size: 18px;
  text-indent: 2em;
}

.link-text {
  color: #f78f2a;
  font-size: 18px;
}

.wrap {
  display: flex;
  justify-content: center;
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  margin: 0 20px;
  background: #f7f9fc;
  border-radius: 6px;
  &-icon {
    width: 32px;
    height: 28px;
    margin-bottom: 14px;
  }

  &-name {
    color: #666;
    font-size: 16px;
  }
}

.list {
  color: #666;
  font-size: 18px;
  &-wrap {
    padding: 20px 32px 0 32px;
    margin: 20px auto 0 auto;
    border: 1px solid #507cff;
    border-radius: 4px;
  }
  &-title {
    margin-bottom: 20px;
    color: #507cff;
    font-size: 18px;
    font-weight: bold;
  }
  &-item {
    display: inline-block;
    width: 50%;
    margin-bottom: 18px;
    &:nth-child(2n) {
      text-align: right;
    }
  }
}
</style>
