<template>
  <!-- 删除按钮 -->
  <el-button class="delBtn" type="text" @click="del">
    <slot>删除</slot>
  </el-button>
</template>

<script>
export default {
  name: 'DelBtn',
  props: {
    req: Function, // 发送的请求
    params: Object, // 请求参数
    list: Array, // 删除数据所在列表
    index: Number, // 删除数据索引,
    page: Number, // 当前页
    totalPage: Number // 总页数
  },
  methods: {
    del() {
      this.$confirm('删除后不能恢复，确定要删除吗？', '删除确认', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'del'
      })
        .then(() => {
          this.req(this.params)
            .then(res => {
              let messageType = 'warning';
              if (res.status === 200) {
                messageType = 'success';
                // 删除表格里对应项
                this.list.splice(this.index, 1);
                // 判断是否删完
                // 判断该页数据是否为空
                if (this.list.length === 0) {
                  let page = this.page;
                  if (page) {
                    // 总页数不为1，并且当前页为最后一页
                    if (this.totalPage !== 1 && page === this.totalPage) {
                      page--;
                    }
                    this.$emit('changePage', page);
                  }
                }
              }
              // 操作提示信息
              this.$message({
                type: messageType,
                message: res.msg
              });
            })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '请求错误'
              });
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.delBtn {
  color: #ff2626;
  font-size: 18px;
}
</style>
