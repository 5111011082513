<template>
  <el-container>
    <!-- 头部 -->
    <el-header class="header" height="2.70833vw">
      <!-- 左侧 -->
      <div class="header-left">
        <div class="header-title">
          <div class="header-title-icon" />
          我的服务
        </div>
      </div>
      <!-- 右侧输入框 -->
      <div class="header-right">
        <Search v-model="keywords" @search="handleSearch" />
      </div>
    </el-header>
    <!-- 主体 -->
    <el-container class="main-container">
      <el-header class="main-header" height="3.125vw">
        <!-- 服务类型 -->
        <div class="radio">
          <p class="radio-label">服务类型:</p>
          <el-radio-group v-model="type" @change="handleSearch">
            <el-radio-button
              v-for="item in serviceTypes"
              :key="'serviceType_' + item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <!-- 状态 -->
        <div class="radio">
          <p class="radio-label">状态:</p>
          <el-radio-group v-model="enable" @change="handleSearch">
            <el-radio-button
              v-for="item in serviceStates"
              :key="'serviceState_' + item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </el-header>
      <el-main class="main">
        <!-- 表格 -->
        <div class="table-wrap">
          <el-table :data="tableData" cell-class-name="noBorderBottom">
            <el-table-column width="36" />
            <el-table-column label="服务名称" prop="name">
              <template slot-scope="{ row }">
                <p>{{ row.name }}</p>
                <p v-if="row.expireHint">{{ row.expireHint }}</p>
              </template>
            </el-table-column>
            <el-table-column label="服务类型" prop="typeName" />
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span :class="['state', `state-${scope.row.enable}`]">{{
                  scope.row.enable ? '已启用' : '已禁用'
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <div class="fnBtns">
                  <template v-if="scope.row.expireHint">
                    <el-button class="setBtn" type="text" @click="setting"
                      >重新发布</el-button
                    >
                    <p class="splitLine"></p>
                  </template>
                  <!-- <el-button class="previewBtn" type="text">预览</el-button> -->
                  <!-- <span class="splitLine"></span> -->
                  <StateBtn
                    :req="$service.giss.serviceChangeEnableState"
                    :item="scope.row"
                    keyName="enable"
                    :params="{
                      id: scope.row.id
                    }"
                    :style="{ color: scope.row.enable ? '#FF8929' : '#33D742' }"
                    @changed="getList"
                  />
                  <span class="splitLine"></span>
                  <DelBtn
                    :req="$service.giss.serviceDel"
                    :params="{ id: scope.row.id }"
                    :list="tableData"
                    :index="scope.$index"
                    @changePage="handleDel"
                  />
                </div>
              </template>
            </el-table-column>
            <template #empty>
              <div class="noData">
                <img class="noData-img" src="~a/workbench/noData.png" alt="" />
                <div class="noData-text">
                  暂无服务，<router-link
                    class="link"
                    to="/workbench/releaseService"
                    >前往发布</router-link
                  >
                </div>
              </div>
            </template>
          </el-table>
        </div>
        <el-pagination
          class="pagination"
          v-if="tableData.length > 0"
          background
          :current-page="page"
          :page-count="totalPage"
          @current-change="handleCurrentChange"
        />
      </el-main>
    </el-container>
    <!-- 服务过期提醒 -->
    <ServiceExpiredDialog
      v-if="serviceExpiredDialogVisible"
      @close="serviceExpiredDialogVisible = false"
    />
  </el-container>
</template>

<script>
import Search from 'c/workbench/Search';
import DelBtn from 'c/workbench/DelBtn';
import StateBtn from 'c/workbench/StateBtn';
import ServiceExpiredDialog from 'c/workbench/ServiceExpiredDialog';
export default {
  name: 'MyService',
  components: {
    Search,
    DelBtn,
    StateBtn,
    ServiceExpiredDialog
  },
  data() {
    return {
      serviceExpiredDialogVisible: false,
      keywords: '',
      type: '',
      serviceTypes: [
        { label: '全部', value: '' },
        { label: 'WMS', value: 1 }
        // { label: 'WMTS', value: 2 },
        // { label: 'WFS', value: 3 }
      ],
      enable: '',
      serviceStates: [
        { label: '全部', value: '' },
        { label: '启用', value: 1 },
        { label: '禁用', value: 0 }
      ],
      tableData: [],
      page: 1,
      totalPage: 1
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 设置
    setting() {},
    // 取消
    cancel() {},
    handleDblclick(row) {
      console.log('进入编辑页面', row);
    },
    handleSearch() {
      this.getList();
    },
    handleDel() {
      // 判断当前页是否删完
      if (this.tableData.length > 1) {
        this.getList();
      } else {
        // 判断当页
        if (this.page >= this.totalPage) {
          this.pgae--;
        }
        this.getList();
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      const { keywords, type, enable, page } = this;
      const params = { keywords, type, enable, page };
      this.$service.giss.serviceMy(params).then(res => {
        const { status, data } = res;
        if (status === 200) {
          this.tableData = data;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
// 单选框
.radio {
  display: flex;
  align-items: center;
  margin-right: 200px;
  color: #333333;
  font-size: 20px;
  &:last-child {
    margin-right: 0;
  }
  &-label {
    margin-right: 20px;
  }

  /deep/ .el-radio-button {
    &:last-child {
      margin-right: 0;
    }
    &__inner {
      padding: 6px 27px;
      border: none;
      border-radius: 15px;
      box-shadow: none;
      color: #333333;
      font-size: 18px;
    }
    &.is-active {
      .el-radio-button__inner {
        background: #eaf2ff;
        color: #3d6eff;
      }
    }
  }
}

// 没有数据
.noData {
  margin: 100px 0;
  line-height: initial;
  &-img {
    width: 250px;
    height: 162px;
    margin-bottom: 45px;
  }
  &-text {
    font-size: 18px;
  }
}

// 状态
.state {
  display: inline-block;
  width: 95px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 6px;
  &-false,
  &-0 {
    color: #ff8929;
    border: 1px solid #ff8929;
    background-color: rgba(255, 137, 41, 0.1);
  }
  &-true,
  &-1 {
    color: #33d742;
    border: 1px solid #33d742;
    background-color: rgba(51, 215, 66, 0.1);
  }
}

// 操作按钮
.fnBtns {
  display: flex;
  align-items: center;
}

// 设置按钮

// 预览按钮
.previewBtn,
.setBtn {
  color: #2371f6;
  font-size: 18px;
}

// 操作按钮分割线
.splitLine {
  display: inline-block;
  width: 1px;
  height: 17px;
  margin: 0 12px;
  background-color: #d2d2d2;
}

.table-wrap {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.pagination {
  display: flex;
  justify-content: center;
}

.expireHint {
  color: #ff0000;
  font-size: 12px;
}
</style>
