<template>
  <el-button type="text" class="stateBtn" @click="changeState">
    <slot>{{ item[keyName] ? disableText : enableText }}</slot>
  </el-button>
</template>

<script>
export default {
  name: 'StateBtn',
  props: {
    item: Object,
    keyName: {
      type: String,
      default: 'state'
    },
    enableText: {
      type: String,
      default: '启用'
    },
    disableText: {
      type: String,
      default: '禁用'
    },
    req: Function, // 请求
    params: Object // 参数
  },
  computed: {
    // 提示信息
    confirmMessage() {
      return `确定要${
        !this.item[this.keyName] ? this.enableText : this.disableText
      }吗`;
    },
    // 提示标题
    confirmTitle() {
      return `${
        !this.item[this.keyName] ? this.enableText : this.disableText
      }确认`;
    },
    // 确定按钮文字
    confirmBtnText() {
      return !this.item[this.keyName] ? this.enableText : this.disableText;
    }
  },
  methods: {
    changeState() {
      this.$confirm(this.confirmMessage, this.confirmTitle, {
        confirmButtonText: this.confirmBtnText,
        cancelButtonText: '取消',
        type: 'warning',
        // 确定按钮类名
        confirmButtonClass: this.item[this.keyName] ? 'disable' : 'enable'
      })
        .then(() => {
          const state = this.item[this.keyName] ? 0 : 1;
          let params = {};
          if (this.params) {
            params = this.params;
          }
          params[this.keyName] = state;
          // 发送请求
          this.req(params).then(res => {
            let messageType = 'warning';
            if (res.status === 200) {
              messageType = 'success';
              // 修改状态
              this.item[this.keyName] = state;
            }
            this.$message({
              type: messageType,
              message: res.msg
            });
            this.$emit('changed');
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.stateBtn {
  font-size: 18px;
}
</style>
